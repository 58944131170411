// TODO: いつか以下のライブラリを最適化した構造のスクリプトに変更したい
// https://github.com/elgorditosalsero/react-gtm-hook
// これをそのまま使うのは冗長さがあるので避けたい。
import { FC } from "react";

declare global {
  interface Window {
    dataLayer: object[];
  }
}

export const GtmScript: FC = () => {
  if (!process.env.GTM_ID) return <></>;
  const gtmId = process.env.GTM_ID;
  const auth = process.env.GTM_AUTH;
  const env = process.env.GTM_ENV;
  return (
    <script dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${auth}&gtm_preview=${env}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`
    }} />
  )
}

export const GtmNoScript = () => {
  if (!process.env.GTM_ID) return <></>;
  const auth = process.env.GTM_AUTH;
  const env = process.env.GTM_ENV;
  const gtmId = process.env.GTM_ID;
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}&gtm_auth=${auth}&gtm_preview=${env}&gtm_cookies_win=x`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  );
};

export const pushToDataLayer = (obj: object): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};
