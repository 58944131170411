import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { pagesReducer } from "./pages";

export const rootReducer = combineReducers({
  pages: pagesReducer
});

export const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>;
