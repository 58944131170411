import React, { createContext, Dispatch, useContext, useEffect, useReducer } from "react";
import { useScript } from "../../../lib/hooks/useScript";

declare global {
  interface Window {
    zE: any;
    zESettings: any;
  }
}

export type ZendeskContextState = {
  available: boolean | undefined;
  showsLauncher: boolean;
};
const initialState: ZendeskContextState = {
  available: undefined,
  showsLauncher: false,
};

export type ZendeskContextType = {
  state: ZendeskContextState;
  dispatch: Dispatch<Action>;
};
export const ZendeskContext = createContext<ZendeskContextType>({
  state: initialState,
  dispatch: () => {},
});

export const useZendeskContext = () => {
  return useContext(ZendeskContext);
};

type Action = {
  type: "initialize" | "closeChat" | "openChat" | "showLauncher" | "hideLauncher" | "enable" | "disable";
};

const zendeskReducer = (state: ZendeskContextState, action: Action): ZendeskContextState => {
  if (window.zE) {
    switch (action.type) {
      case "initialize":
        window.zE("webWidget", "hide");
        return state;
      case "openChat":
        window.zE("webWidget", "show");
        window.zE("webWidget", "open");
        return { ...state, showsLauncher: false };
      case "closeChat":
        window.zE("webWidget", "hide");
        return { ...state, showsLauncher: true };
      case "showLauncher":
        return { ...state, showsLauncher: true };
      case "hideLauncher":
        return { ...state, showsLauncher: false };
      case "enable":
        return { ...state, available: true };
      case "disable":
        return { ...state, available: false };
    }
    return state;
  } else {
    return { ...state, available: false };
  }
};

export const useZendeskReducer = (): [ZendeskContextState, Dispatch<Action>] => {
  const scriptStatus = useScript("https://static.zdassets.com/ekr/snippet.js?key=4ee53dde-c5f8-44cf-86b5-de2bd4afafd3", {
    id: "ze-snippet",
  });
  const [state, dispatch] = useReducer(zendeskReducer, initialState);
  useEffect(() => {
    if (scriptStatus === "ready" && window.zE) {
      dispatch({ type: "initialize" });
      window.zE("webWidget:on", "close", () => {
        dispatch({ type: "closeChat" });
      });
      window.zE("webWidget:on", "chat:status", (status: "online" | "offline" | "away") => {
        dispatch({ type: status === "online" ? "enable" : "disable" });
      });
    } else {
      dispatch({ type: "disable" });
    }
  }, [scriptStatus]);
  return [state, dispatch];
};
