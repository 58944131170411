import { AppProps } from "next/app";
import "../public/css/style.css";
import "react-toastify/dist/ReactToastify.min.css";
import "swiper/swiper-bundle.min.css";
import * as Sentry from "@sentry/node";
import { useZendeskReducer, ZendeskContext } from "components/front/layout/Zendesk";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "modules";
import dynamic from "next/dynamic";
import { Props as StyledToastContainerProps } from "components/common/StyledToastContainer";
import usePageView from "lib/hooks/front/usePageView";

const StyledToastContainer = dynamic<StyledToastContainerProps>(() => import("components/common/StyledToastContainer"));

const App = ({ Component, pageProps }: AppProps): React.ReactNode => {
  usePageView();
  if (process.env.SENTRY_DSN) {
    Sentry.init({
      environment: process.env.APP_ENV,
      dsn: process.env.SENTRY_DSN,
    });
  }
  const [zendeskState, zendeskDispatch] = useZendeskReducer();
  return (
    <>
     <ReduxProvider store={store}>
        <ZendeskContext.Provider value={{ state: zendeskState, dispatch: zendeskDispatch }}>
          <Component {...pageProps} />
        </ZendeskContext.Provider>
      </ReduxProvider>
      <StyledToastContainer />
    </>
  );
};

export default App;
