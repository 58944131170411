import { useState, useEffect } from "react";

export type Status = "idle" | "loading" | "ready" | "error";
export type ScriptElt = HTMLScriptElement | null;

export function useScript(src: string, attr?: { id?: string }): Status {
  const [status, setStatus] = useState<Status>(src ? "loading" : "idle");
  useEffect(() => {
    if (!src) {
      setStatus("idle");
      return;
    }
    let script: ScriptElt = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = src;
      if (attr?.id) script.id = attr.id;
      script.async = true;
      script.setAttribute("data-status", "loading");
      document.body.appendChild(script);
      const setAttributeFromEvent: EventListenerOrEventListenerObject = (event: Event) => {
        script?.setAttribute("data-status", event.type === "load" ? "ready" : "error");
      };
      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute("data-status") as Status);
    }
    const setStateFromEvent: EventListenerOrEventListenerObject = (event: Event) => {
      setStatus(event.type === "load" ? "ready" : "error");
    };
    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);
    return (): void => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [src]);
  return status;
}
